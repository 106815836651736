import axios from "../../axios/axios";
import { NotificationManager } from "../../components/common/react-notifications/";

export const SELECT_PUBLICATION = "[HOME] SELECT PUBLICATION";
export const SELECT_PUBLICATIONS = "[HOME] SELECT PUBLICATIONS";
export const LOAD_ADV_PRICES = "[HOME] LOAD ADV PRICES";
export const LOGIN_TOGGLE = "[HOME] LOGIN TOGGLE";
export const START_BOOKING = "[HOME] START BOOKING";
export const SELECT_BOOKING_ID = "[HOME] SELECT BOOKING ID";
export const CLEAR_BOOKING_ID = "[HOME] CLEAR BOOKING ID";
export const CLEAR_PUBLICATION = "[HOME] CLEAR PUBLICATION";
export const LOAD_STATS = "[HOME] LOAD STATS";
export const REMOVE_PUBLICATION = "[HOME] REMOVE PUBLICATION";

export const select_publication = (id) => {
  return {
    type: SELECT_PUBLICATION,
    payload: id,
  };
};
export const select_publications = (arr) => {
  // console.log(arr);
  return {
    type: SELECT_PUBLICATIONS,
    payload: arr,
  };
};
export const remove_publication = (id) => {
  return (dispatch, getState) => {

    // console.log(getState().home.publications.filter((v) => parseInt(v) !== id));

    dispatch( {
      type: REMOVE_PUBLICATION,
      payload: getState().home.publications.filter((v) => v !== id),
    });
  };
};
export const clear_publication = () => {
  return {
    type: CLEAR_PUBLICATION,
  };
};
export const load_adv_prices = (arr) => {
  return (dispatch, getState) => {
    const sendData = {
      advPriceIds: arr,
    };
    axios.post("/adv_prices/get_adv_prices", sendData).then((response) => {
      // console.log(response.data);
      dispatch({
        type: LOAD_ADV_PRICES,
        payload: response.data,
      });
    });
  };
};

export const alert = (type, title, description) => {
  switch (type) {
    case "error":
      NotificationManager.error(description, title, 3000, null, null, "filled");
      break;
    case "warning":
      NotificationManager.warning(
        description,
        title,
        3000,
        null,
        null,
        "filled"
      );
      break;
    case "success":
      NotificationManager.success(
        description,
        title,
        3000,
        null,
        null,
        "filled"
      );
      break;
    default:
      NotificationManager.info(description, title, 3000, null, null, "filled");
  }
};

export const createLoggedBooking = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: START_BOOKING,
    });
    // console.log(data.publication.penmag);
    const defaultArr = [];
    if (data.selectedDays.penmag.length > 0) {
      //penmag entry
      const dy = [];
      data.selectedDays.penmag.map((e) => {
        const d = new Date(e);
        const month =
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1);
        const date = d.getFullYear() + "-" + month + "-" + d.getDate();
        dy.push(date);
      });
      const penData = {
        ...data.publication.penmag,
        dates: dy,
        bookingRequirements: [1],
      };
      defaultArr.push(penData);
    }

    if (data.selectedDays.alwaseeth.length > 0) {
      //penmag entry
      const dy = [];
      data.selectedDays.alwaseeth.map((e) => {
        const d = new Date(e);
        const month =
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1);
        const date = d.getFullYear() + "-" + month + "-" + d.getDate();
        dy.push(date);
      });
      const alwaseethData = {
        ...data.publication.alwaseeth,
        dates: dy,
        bookingRequirements: [1],
      };
      defaultArr.push(alwaseethData);
    }

    const sendData = {
      billingAddress: "-",
      paymentMethodId: "1",
      adv: defaultArr,
    };
    // console.log(sendData);
    axios.post("/customers/booking_create", sendData).then((response) => {
      // console.log("");

      dispatch({
        type: SELECT_BOOKING_ID,
        payload: response.data.data.order.id,
      });
    });
  };
};

export const createLoggedGuest = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: START_BOOKING,
    });
    // console.log(data.publication.penmag);
    const defaultArr = [];
    if (data.selectedDays.penmag.length > 0) {
      //penmag entry
      const dy = [];
      data.selectedDays.penmag.map((e) => {
        const d = new Date(e);
        const month =
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1);
        const date = d.getFullYear() + "-" + month + "-" + d.getDate();
        dy.push(date);
      });
      const penData = {
        ...data.publication.penmag,
        dates: dy,
        bookingRequirements: [1],
      };
      defaultArr.push(penData);
    }

    if (data.selectedDays.alwaseeth.length > 0) {
      //penmag entry
      const dy = [];
      data.selectedDays.alwaseeth.map((e) => {
        const d = new Date(e);
        const month =
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1);
        const date = d.getFullYear() + "-" + month + "-" + d.getDate();
        dy.push(date);
      });
      const alwaseethData = {
        ...data.publication.alwaseeth,
        dates: dy,
        bookingRequirements: [1],
      };
      defaultArr.push(alwaseethData);
    }

    const sendData = {
      billingAddress: "-",
      paymentMethodId: "1",
      country: data.country,
      email: data.email,
      adv: defaultArr,
      customer: data.customer,
    };
    console.log(data);
    // console.log(sendData);
    axios.post("/customers/booking_create", sendData).then((response) => {
      // console.log("");

      dispatch({
        type: SELECT_BOOKING_ID,
        payload: response.data.data.order.id,
      });
    });
  };
};

export const load_single_order = (id) => {
  const out = axios.get(`/customers/orders_single/${id}`);
  return out;
};

export const load_stats = () => {
  return (dispatch, state) => {
    axios.get("/customers/dashboard_stats").then((response) => {
      dispatch({
        type: LOAD_STATS,
        payload: response.data.success,
      });
    });
  };
};
