import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  LOGIN_TOGGLE,
  REGISTER_TOGGLE,
  GUEST_TOGGLE,
  ADD_GUEST,
} from "../actions";

const INIT_STATE = {
  user: null,
  forgotUserMail: "",
  newPassword: "",
  resetPasswordCode: "",
  loading: false,
  error: "",
  login: false,
  register: false,
  guestlogin: false,
  guest: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: "" };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        login: false,
        register: false,
        error: "",
      };
    case LOGIN_USER_ERROR:
      return { ...state, loading: false, user: null, error: action.payload };
    case ADD_GUEST:
      return {
        ...state,
        guest: action.payload,
        loading: false,
        user: null,
        login: false,
        register: false,
        guestlogin: false,
      };
    case LOGIN_TOGGLE:
      return {
        ...state,
        login: action.payload.login,
        register: action.payload.register,
        guestlogin: action.payload.guestlogin,
      };
    case GUEST_TOGGLE:
      return {
        ...state,
        login: action.payload.login,
        register: action.payload.register,
        guestlogin: action.payload.guestlogin,
      };
    case REGISTER_TOGGLE:
      return {
        ...state,
        login: action.payload.login,
        register: action.payload.register,
        guestlogin: action.payload.guestlogin,
      };
    case FORGOT_PASSWORD:
      return { ...state, loading: true, error: "" };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotUserMail: action.payload,
        error: "",
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotUserMail: "",
        error: action.payload.message,
      };
    case RESET_PASSWORD:
      return { ...state, loading: true, error: "" };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        newPassword: action.payload,
        resetPasswordCode: "",
        error: "",
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        newPassword: "",
        resetPasswordCode: "",
        error: action.payload.message,
      };
    case REGISTER_USER:
      return { ...state, loading: true, error: "" };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        login: false,
        register: false,
        error: "",
      };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: "",
        error: action.payload,
      };
    case LOGOUT_USER:
      return { ...state, user: null, error: "" };
    default:
      return { ...state };
  }
};
