import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import home from './home/reducer';
import auth from './auth/reducer'
import order from './order/reducer';

const reducers = combineReducers({
  menu,
  settings,
  auth,
  home,
  order
});

export default reducers;