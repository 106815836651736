import Axios from 'axios';

const instance = Axios.create({
    // baseURL:'http://asc-media.web/api',
    baseURL:'https://ascapi.alsharqtech.com/api',
    headers: {
    'Accept': 'application/json',
    
    // 'Content-Type':'application/json; charset=utf-8',
    // 'Data-Type':'json'
	}
});

export default instance;