import { LOAD_ORDER, LOAD_SINGLE_ORDER, LOAD_PAYMENT } from "./actions";

const INIT_STATE = {
  orders: null,
  payment: null,
  order:null,
  payment_list: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_ORDER:
      return {
        ...state,
        ...state.payment,
        orders: action.payload,
      };
      case LOAD_SINGLE_ORDER:
      return{
        ...state,
        order: action.payload
      } 
      case LOAD_PAYMENT:
        return {
          ...state,
          payment_list: action.payload
        }
    default:
      return { ...state };
  }
};
