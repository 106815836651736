import axios from "../../axios/axios";

export const LOAD_ORDER = "[ORDERLIST] LOAD ORDER";
export const LOAD_SINGLE_ORDER = "[ORDERLIST] LOAD SINGLE ORDER";
export const LOAD_PAYMENT = "[ORDERLIST] LOAD PAYMENT";

// export const load_order = () => {
//     return (dispatch, getState) => {
//         axios.get('/customers/orders_list?order_by=id&order=desc').then(response => {
//             //  console.log(response.data);
//             dispatch({
//                 type: LOAD_ORDER,
//                 payload: response.data
//             });
//         })

//     }
// }

export const load_order = () => {
  return (dispatch, getState) => {
    axios
      .get("/customers/bookings_list?order_by=id&order=desc")
      .then((response) => {
        //  console.log(response.data);
        dispatch({
          type: LOAD_ORDER,
          payload: response.data,
        });
      });
  };
};

export const load_order_page = (page) => {
  return (dispatch, getState) => {
    axios
      .get("/customers/orders_list?order_by=id&order=desc&page=" + page)
      .then((response) => {
        //  console.log(response.data);
        dispatch({
          type: LOAD_ORDER,
          payload: response.data,
        });
      });
  };
};

export const load_guest_order = (id, mob) => {
  return (dispatch, getState) => {
    axios
      .get(`/customers/bookings_list?idType=QID&idNumber=${id}&mobile=${mob}`)
      .then((response) => {
        //  console.log(response.data);
        dispatch({
          type: LOAD_ORDER,
          payload: response.data,
        });
      });
  };
};

export const load_guest_order_page = (id, mob, page) => {
  return (dispatch, getState) => {
    axios
      .get(
        `/customers/bookings_list?idType=QID&idNumber=${id}&mobile=${mob}&page=${page}`
      )
      .then((response) => {
        //  console.log(response.data);
        dispatch({
          type: LOAD_ORDER,
          payload: response.data,
        });
      });
  };
};

export const load_single_order = (id) => {
  return (dispatch, getState) => {
    axios.get(`/customers/orders_single/${id}`).then((response) => {
      //  console.log(response.data);
      dispatch({
        type: LOAD_SINGLE_ORDER,
        payload: response.data,
      });
    });
  };
};

export const load_single_order_guest = (id, idNumber, mobile) => {
  return (dispatch, getState) => {
    axios
      .get(
        `/customers/orders_single/${id}?idType=QID&idNumber=${idNumber}&mobile=${mobile}`
      )
      .then((response) => {
        //  console.log(response.data);
        dispatch({
          type: LOAD_SINGLE_ORDER,
          payload: response.data,
        });
      });
  };
};

export const load_payment = () => {
  return (dispatch, getState) => {
    axios
      .get("/customers/payment_list?order_by=id&order=desc")
      .then((response) => {
        //  console.log(response.data);
        dispatch({
          type: LOAD_PAYMENT,
          payload: response.data,
        });
      });
  };
};



export const load_payment_page = (page) => {
    return (dispatch, getState) => {
      axios
        .get("/customers/payment_list?order_by=id&order=desc&page=" + page)
        .then((response) => {
          //  console.log(response.data);
          dispatch({
            type: LOAD_PAYMENT,
            payload: response.data,
          });
        });
    };
  };