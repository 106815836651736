import * as actions from "./actions";

const INIT_STATE = {
  publications: [],
  prices: { alwaseeth: null, penmag: null },
  login: true,
  bookingId: null,
  bookingCreate: false,
  stats: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case actions.SELECT_PUBLICATION:
      let store = state.publications;

      if (!store.includes(action.payload)) {
        store.push(action.payload);
      } else {
        store = store.filter(function (item) {
          return item !== action.payload;
        });
      }
      return {
        ...state,
        ...state.prices,
        publications: store,
      };
    case actions.CLEAR_PUBLICATION:
      return { ...state, ...state.prices, publications: [], bookingId: null };
    case actions.SELECT_PUBLICATIONS:
      let stores = action.payload;
      // console.log("SHOW", stores);

      return {
        ...state,
        ...state.prices,
        publications: stores,
      };
    case actions.LOAD_ADV_PRICES:
      return {
        ...state,
        prices: action.payload,
      };
    case actions.LOGIN_TOGGLE:
      return {
        ...state,
        login: action.payload,
      };
    case actions.START_BOOKING:
      return {
        ...state,
        ...state.prices,
        bookingCreate: true,
      };
    case actions.SELECT_BOOKING_ID:
      return {
        ...state,
        ...state.prices,
        bookingCreate: false,
        bookingId: action.payload,
      };
    case actions.CLEAR_BOOKING_ID:
      return {
        ...state,
        ...state.prices,
        bookingId: null,
      };
    case actions.LOAD_STATS:
      return {
        ...state,
        ...state.prices,
        stats: action.payload,
      };
    case actions.REMOVE_PUBLICATION:
      // console.log("reducer", action.payload);
      return {
        ...state,
        ...state.prices,
        publications: action.payload,
      };
    default:
      return { ...state };
  }
};
