import axios from "../../axios/axios";
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  LOGIN_TOGGLE,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  REGISTER_TOGGLE,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  GUEST_TOGGLE,
  ADD_GUEST,
} from "../actions";
import { NotificationManager } from "../../components/common/react-notifications/";

export const alert = (type, title, description) => {
  switch (type) {
    case "error":
      NotificationManager.error(description, title, 3000, null, null, "filled");
      break;
    case "warning":
      NotificationManager.warning(
        description,
        title,
        3000,
        null,
        null,
        "filled"
      );
      break;
    case "success":
      NotificationManager.success(
        description,
        title,
        3000,
        null,
        null,
        "filled"
      );
      break;
    default:
      NotificationManager.info(description, title, 3000, null, null, "filled");
  }
};

const setSession = (access_token) => {
  if (access_token) {
    localStorage.setItem("pwx", access_token);
    axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
  } else {
    localStorage.removeItem("pwx");
    localStorage.removeItem("ldx");
    delete axios.defaults.headers.common.Authorization;
  }
};
export const addGuestLogin = (data) => {
  return (dispatch) => {
    dispatch({
      type: ADD_GUEST,
      payload: {
        nameEnglish: data.nameEnglish,
        mobile: data.mobile,
        idNumber: data.idNumber,
        email: data.email,
        country: data.country,
        idType: "QID",
        customerTypeId: 1,
      },
    });
  };
};
export const loginUser = (user, password) => {
  return (dispatch, getState) => {
    const sendData = {
      username: user,
      password: password,
    };
    dispatch({
      type: LOGIN_USER,
    });
    axios.post("/customers/login", sendData).then((response) => {
      // console.log(response);
      if (response.data.success || response.data.user) {
        alert("success", `Welcome ${user}`, "Your credential are accepted");
        setSession(response.data.accessToken);
        localStorage.setItem("ldx", response.data.refreshToken);
        localStorage.setItem("exp", response.data.accessTokenExpiration);
        dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: response.data.user,
        });
      } else {
        if (typeof response.data.error === "object") {
          Object.keys(response.data.error).map((res) => {
            alert("error", "Error", response.data.error[res][0]);
          });
        } else {
          alert("error", "Error", response.data.error);
        }
        return {
          type: LOGIN_USER_ERROR,
          payload: "System Error",
        };
      }
    });
  };
};

export const access_to_account = () => {
  // return (dispatch, getState) => {

  return new Promise((resolve, reject) => {
    axios.defaults.headers.common.Authorization = `Bearer ${getAccessToken()}`;
    // console.log(getAccessToken());
    axios
      .get("/customers/get_profile")
      .then((response) => {
        // console.log(response.data.success);
        if (!response.data.message) {
          // console.log("Error", response.data.user.userId);
          setSession(getAccessToken());

          return resolve(response.data.success);
          //return Promise.reject(new Error('fail')).then((resolve) => {}, () => { console.log("error", response.data) });
        } else {
          logout();
          //  return reject(response.data.message);
          // console.log("error");
          return reject(new Error("fail"));
        }
      })
      .catch((error) => {
        logout();
        // console.log("system error");
        return reject(new Error("fail"));
      });
  });

  // }
};

export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history },
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail,
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message },
});

export const resetPassword = ({ resetPasswordCode, newPassword, history }) => ({
  type: RESET_PASSWORD,
  payload: { resetPasswordCode, newPassword, history },
});
export const resetPasswordSuccess = (newPassword) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword,
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message },
});

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history },
});
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});

export const registerNewUser = (data) => {
  return (dispatch, getState) => {
    const date = new Date(data.dateOfBirth);
    const month =
      date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1);
    const day = date.getFullYear() + "-" + month + "-" + date.getDate();

    const sendData = {
      username: data.username,
      password: data.password,
      // confirmPassword: data.rpassword,
      confirmPassword: data.password,
      email: data.email,
      initials: data.initials,
      firstName: data.firstName,
      lastName: data.lastName,
      dateOfBirth: day,
      gender: data.gender,
      country: data.country,
      mobile: data.mobile,
      customerTypeId: data.customerTypeId,
      idType: data.idType,
      idNumber: data.idNumber,
    };
    dispatch({
      type: REGISTER_USER,
    });
    axios.post("/register", sendData).then((response) => {
      // console.log(response);
      if (response.data.success || response.data.user) {
        alert(
          "success",
          `Welcome ${data.username}`,
          "Your registration have been completed successfully"
        );
        // console.log(response.data);
        setSession(response.data.accessToken);
        localStorage.setItem("ldx", response.data.refreshToken);
        localStorage.setItem("exp", response.data.accessTokenExpiration);
        dispatch(registerUserSuccess(response.data.success.user));
      } else {
        if (typeof response.data.error === "object") {
          Object.keys(response.data.error).map((res) => {
            alert("error", "Error", response.data.error[res][0]);
            return;
          });
        } else {
          alert("error", "Error", response.data.error);
        }
        // dispatch(registerUserError("System Error"));
      }
    });
  };
};

export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: message,
});

export const logoutUser = () => {
  logout();
  return {
    type: LOGOUT_USER,
    // payload: { history },
  };
};

export const login_toggle = () => {
  return (dispatch, getState) => {
    dispatch({
      type: LOGIN_TOGGLE,
      payload: {
        login: !getState().auth.login,
        register: false,
        guestlogin: false,
      },
    });
  };
};

export const login_toggle_guest = () => {
  return (dispatch, getState) => {
    dispatch({
      type: LOGIN_TOGGLE,
      payload: {
        login: true, //!getState().auth.login
        register: false,
        guestlogin: false,
      },
    });
  };
};

export const guest_toggle = () => {
  return (dispatch, getState) => {
    dispatch({
      type: GUEST_TOGGLE,
      payload: {
        login: true,
        register: false,
        guestlogin: !getState().auth.guestlogin,
      },
    });
  };
};
export const register_toggle = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: REGISTER_TOGGLE,
      payload: {
        register: !state.auth.register,
        // login: !state.auth.register ? true : false,
        login: true,
        guestlogin: false,
      },
    });
  };
};

const getAccessToken = () => {
  return window.localStorage.getItem("pwx");
};

const getRefreshToken = () => {
  return window.localStorage.getItem("ldx");
};

const logout = () => {
  setSession(null);
};

export const changePassword = (data) => {
  return (dispatch, getState) => {
    const sendData = {
      password: data.password,
    };
    axios.post("/customers/update_profile", sendData).then((response) => {
      console.log(response);
      if (!response.data.error) {
        return alert("success", "Changed", "Your password have been updated");
      } else {
        if (typeof response.data.error === "object") {
          Object.keys(response.data.error).map((res) => {
            alert("error", "Error", response.data.error[res][0]);
            return;
          });
        } else {
          alert("error", "Error", response.data.error);
        }
        //alert("error", "Error", "Unauthorize Error");
      }
    });
  };
};

export const updateProfile = (data) => {
  return (dispatch, getState) => {
    const sendData = {
      initials: data.initials,
      nameEnglish: data.nameEnglish,
      nameArabic: data.nameArabic,
      gender: data.gender.value,
      customerTypeId: data.customerTypeId,
      contactPerson: data.contactPerson,
      address: data.address,
      country: data.country.value,
      postBoxNumber: data.postBoxNumber,
      telephone: data.telephone,
      mobile: data.mobile,
      idType: data.idType,
      idNumber: data.idNumber,
      email: data.email,
    };
    axios.post("/customers/update_profile", sendData).then((response) => {
      //  console.log(response);
      if (!response.data.error) {
        return alert("success", "Updated", "Your profile changes saved");
      } else {
        if (typeof response.data.error === "object") {
          Object.keys(response.data.error).map((res) => {
            alert("error", "Error", response.data.error[res][0]);
            return;
          });
        } else {
          alert("error", "Error", response.data.error);
        }
        //alert("error", "Error", "Unauthorize Error");
      }
    });
  };
};
